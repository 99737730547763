
import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XButton from "@/components/SimpleButton.vue";

@Component({
  components: { Page, XButton },
})
class PaymentsNoAuthFail extends Vue {
  getText() {
    return 'Платеж проведен успешно. Обработка платежа может составить несколько часов';
  }
  redirect() {
    this.$router.push({ name: "auth" });
  }
}

export default PaymentsNoAuthFail;
